import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import {
  GetResourceTypesQuery,
  Kind,
  useGetResourceTypesQuery,
} from "../../graphql/generated";
import { metadataSatisfiesSubstring } from "../../utils/metadata-satisfies-substring";
import { ActionsSection } from "../DialogComponents";
import {
  ResourceTypeButton,
  ResourceTypeButtonContainer,
} from "../ResourceTypeButton";
import { usePipelineGraph } from "../PipelineGraph/PipelineGraphContext";
import { ViewHeading } from "./ViewHeading";

import styles from "./select-view.module.scss";
import mixins from "../../styles/mixins.module.scss";
import { Stability } from "../../types/resources";

interface SelectViewProps {
  resourceKind: Kind.Processor | Kind.Extension;

  // The supported telemetry types of the source that the processor will be added to
  telemetryTypes?: string[];

  onBack?: () => void;
  onSelect: (rt: GetResourceTypesQuery["resourceTypes"][0]) => void;
}

export const SelectView: React.FC<SelectViewProps> = ({
  onBack,
  onSelect,
  telemetryTypes,
  resourceKind,
}) => {
  const { data, loading, error } = useGetResourceTypesQuery({
    variables:
      resourceKind === Kind.Processor
        ? { kind: Kind.ProcessorType }
        : { kind: Kind.ExtensionType },
  });
  const [search, setSearch] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (error != null) {
      enqueueSnackbar("Error retrieving data for Resource Type.", {
        variant: "error",
        key: "Error retrieving data for Resource Type.",
      });
    }
  }, [enqueueSnackbar, error]);

  const { editProcessorsInfo } = usePipelineGraph();
  const resourceType = useMemo(
    () => editProcessorsInfo?.resourceType,
    [editProcessorsInfo?.resourceType],
  );

  const title = `Add ${
    resourceKind === Kind.Processor ? "a" : "an"
  } ${resourceKind.toLowerCase()}`;
  const description = `Choose ${
    resourceKind === Kind.Processor ? "a" : "an"
  } ${resourceKind.toLowerCase()} you'd like to configure for this ${resourceType}.`;

  // Filter the list of supported resource types down
  // to those whose telemetry matches the telemetry of the
  // source. i.e. don't show a log processor for a metric source
  const supportedProcessorTypes: GetResourceTypesQuery["resourceTypes"] =
    useMemo(
      () =>
        telemetryTypes
          ? data?.resourceTypes.filter((pt) =>
              pt.spec.telemetryTypes.some((t) => telemetryTypes.includes(t)),
            ) ?? []
          : data?.resourceTypes ?? [],
      [data?.resourceTypes, telemetryTypes],
    );

  // Filter the list of supported processor types down to those matching the search,
  // and sort them in alphabetical order by display name
  const matchingResourceTypes: GetResourceTypesQuery["resourceTypes"] = useMemo(
    () =>
      supportedProcessorTypes
        .filter((pt) => metadataSatisfiesSubstring(pt, search))
        .sort((a, b) =>
          (a.metadata.displayName?.toLowerCase() ?? "").localeCompare(
            b.metadata.displayName?.toLowerCase() ?? "",
          ),
        ),
    [supportedProcessorTypes, search],
  );
  const categorizedResourceTypes = resourceTypesByCategory(
    matchingResourceTypes,
  );

  return (
    <Stack className={mixins["flex-grow"]} spacing={2}>
      <Stack spacing={1} alignItems={"center"} overflow={"auto"} flexGrow={1}>
        <ViewHeading heading={title} subHeading={description} />
        <ResourceTypeButtonContainer
          onSearchChange={(v: string) => setSearch(v)}
          placeholder={
            resourceKind === Kind.Processor
              ? "Search for a processor..."
              : resourceKind === Kind.Extension
                ? "Search for an extension..."
                : ""
          }
        >
          {loading && (
            <Box display="flex" justifyContent={"center"} marginTop={2}>
              <CircularProgress />
            </Box>
          )}
          {Object.keys(categorizedResourceTypes)
            .sort((a, b) => a.localeCompare(b))
            .filter((k) => k !== "Advanced")
            .map((k) => (
              <ResourceCategory
                key={k}
                title={k}
                items={categorizedResourceTypes[k]}
                onSelect={onSelect}
              />
            ))}
          {categorizedResourceTypes["Advanced"] && (
            <ResourceCategory
              key="Advanced"
              title="Advanced"
              items={categorizedResourceTypes["Advanced"]}
              onSelect={onSelect}
            />
          )}
        </ResourceTypeButtonContainer>
      </Stack>
      {onBack && (
        <ActionsSection>
          <Button variant="outlined" color="secondary" onClick={onBack}>
            Back
          </Button>
        </ActionsSection>
      )}
    </Stack>
  );
};

function resourceTypesByCategory(
  resourceTypes: GetResourceTypesQuery["resourceTypes"],
): {
  [category: string]: GetResourceTypesQuery["resourceTypes"];
} {
  return resourceTypes.reduce(
    (
      acc: { [key: string]: GetResourceTypesQuery["resourceTypes"] },
      p: GetResourceTypesQuery["resourceTypes"][0],
    ) => {
      const category: string =
        p.metadata.labels?.category?.replaceAll("-", " ") ?? "Other";
      if (!acc[category]) {
        acc[category] = [p];
      } else {
        acc[category] = [...acc[category]!, p];
      }

      return acc;
    },
    {},
  );
}

interface ResourceCategoryProps {
  title: string;
  items: GetResourceTypesQuery["resourceTypes"];
  onSelect: (rt: GetResourceTypesQuery["resourceTypes"][0]) => void;
}

function ResourceCategory({ onSelect, items, title }: ResourceCategoryProps) {
  return (
    <>
      <Box className={styles.category}>
        <Typography fontSize={18} fontWeight={600}>
          {title}
        </Typography>
      </Box>{" "}
      {items.map((p) => (
        <ResourceTypeButton
          hideIcon
          key={p.metadata.name}
          displayName={p.metadata.displayName!}
          onSelect={() => onSelect(p)}
          telemetryTypes={p.spec.telemetryTypes}
          stability={p.metadata.stability ?? Stability.UNKNOWN}
        />
      ))}
    </>
  );
}
