import { Stack } from "@mui/material";
import {
  DataGridPro,
  DataGridProProps,
  GridCellParams,
  GridColDef,
  GridRowSelectionModel,
  GridValueGetterParams,
} from "@mui/x-data-grid-pro";
import { isFunction } from "lodash";
import { useSnackbar } from "notistack";
import { memo } from "react";
import {
  ConfigurationTableMetricsSubscription,
  DestinationsQuery,
  PipelineType,
  useGetDestinationsSummaryDataQuery,
} from "../../../graphql/generated";
import { useOverviewPage } from "../../../pages/overview/OverviewPageContext";
import { convertUnits, orderedByteUnits } from "../../../utils/graph/utils";
import { DEFAULT_DESTINATIONS_TABLE_QUERY_PERIOD } from "../../MeasurementControlBar/MeasurementControlBar";
import { createMetricRateColumn } from "../ConfigurationTable/ConfigurationsDataGrid";
import { ConfigurationCountCell } from "../ResourceTable/cells";
import { DestinationTypeCell } from "./cells";

import styles from "./cells.module.scss";

export enum DestinationsTableField {
  NAME = "name",
  TYPE = "type",
  ICON_AND_NAME = "icon",
  LOGS = "logs",
  METRICS = "metrics",
  TRACES = "traces",
  CONFIGURATIONS = "configurations",
  DATA_OUT = "data-out",
}

interface DestinationsDataGridProps
  extends Omit<DataGridProProps, "columns" | "rows"> {
  setSelectionModel?: (names: GridRowSelectionModel) => void;
  onEditDestination: (name: string) => void;
  loading: boolean;
  columnFields?: DestinationsTableField[];
  minHeight?: string;
  maxHeight?: string;
  selectionModel?: GridRowSelectionModel;
  destinationsPage?: boolean;
  allowSelection: boolean;
  destinations?: DestinationsQuery["destinations"];
  configurationMetrics?: ConfigurationTableMetricsSubscription["overviewMetrics"]["metrics"];
}

export const DestinationsDataGrid: React.FC<DestinationsDataGridProps> = memo(
  ({
    setSelectionModel,
    onEditDestination,
    columnFields,
    minHeight,
    maxHeight,
    selectionModel,
    destinationsPage,
    allowSelection,
    destinations,
    configurationMetrics,
    ...dataGridProps
  }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { selectedPeriod } = useOverviewPage();

    // TODO: Consolidate into a single query
    // Using this temporarily to ensure it matches the Summary tables
    const { data: logsTelemetry } = useGetDestinationsSummaryDataQuery({
      variables: {
        period: "1h",
        interval: "24h",
        telemetryType: PipelineType.Logs,
        filterGateways: false,
      },
      onError(error) {
        console.error(error);
        enqueueSnackbar("Failed to load destinations logs summary data", {
          variant: "error",
        });
      },
      pollInterval: 1000 * 60,
    });
    const { data: metricsTelemetry } = useGetDestinationsSummaryDataQuery({
      variables: {
        period: "1h",
        interval: "24h",
        telemetryType: PipelineType.Metrics,
        filterGateways: false,
      },
      onError(error) {
        console.error(error);
        enqueueSnackbar("Failed to load destinations metrics summary data", {
          variant: "error",
        });
      },
      pollInterval: 1000 * 60,
    });
    const { data: tracesTelemetry } = useGetDestinationsSummaryDataQuery({
      variables: {
        period: "1h",
        interval: "24h",
        telemetryType: PipelineType.Traces,
        filterGateways: false,
      },
      onError(error) {
        console.error(error);
        enqueueSnackbar("Failed to load destinations traces summary data", {
          variant: "error",
        });
      },
      pollInterval: 1000 * 60,
    });
    const period = selectedPeriod || DEFAULT_DESTINATIONS_TABLE_QUERY_PERIOD;

    function renderNameCell(
      cellParams: GridCellParams<any, string>,
    ): JSX.Element {
      if (cellParams.row.kind === "Destination") {
        return (
          <button
            onClick={() => onEditDestination(cellParams.value!)}
            className={styles.link}
          >
            {cellParams.value}
          </button>
        );
      }

      return renderStringCell(cellParams);
    }

    function renderNameAndIconCell(
      cellParams: GridCellParams<any, { name: string; type: string }>,
    ): JSX.Element {
      return (
        <>
          <DestinationTypeCell icon type={cellParams?.value?.type ?? ""} />
          <button
            onClick={() => onEditDestination(cellParams.value?.name!)}
            className={styles.link}
          >
            {cellParams.value?.name}
          </button>
        </>
      );
    }

    /**
     * Render `data out` cell, converting bytes to the most appropriate unit
     * Use blank string if no data is available
     */
    function renderDataOutCell(
      cellParams: GridCellParams<any, string>,
    ): JSX.Element {
      const logsOut = logsTelemetry?.destinationsSummary?.find(
        (d) => d.destinationName === cellParams.row.metadata.name,
      )?.totalEgress;
      const metricsOut = metricsTelemetry?.destinationsSummary?.find(
        (d) => d.destinationName === cellParams.row.metadata.name,
      )?.totalEgress;
      const tracesOut = tracesTelemetry?.destinationsSummary?.find(
        (d) => d.destinationName === cellParams.row.metadata.name,
      )?.totalEgress;

      if (
        logsOut === undefined &&
        metricsOut === undefined &&
        tracesOut === undefined
      ) {
        return <div />;
      }

      const { unit, value } = convertUnits(
        {
          value: (logsOut ?? 0) + (metricsOut ?? 0) + (tracesOut ?? 0),
          unit: "B",
        },
        orderedByteUnits,
      );
      return <div>{`${value.toFixed(2)} ${unit}`}</div>;
    }

    function handleRowSelectionModelChange(
      newSelectionModel: GridRowSelectionModel,
    ) {
      if (destinations == null) {
        return;
      }

      isFunction(setSelectionModel) && setSelectionModel(newSelectionModel);
    }

    const columns: GridColDef[] = (columnFields || []).map((field) => {
      switch (field) {
        case DestinationsTableField.NAME:
          return {
            field: DestinationsTableField.NAME,
            width: 300,

            headerName: "Name",
            valueGetter: (params: GridValueGetterParams) =>
              params.row.metadata.name,
            renderCell: renderNameCell,
          };
        case DestinationsTableField.TYPE:
          return {
            field: DestinationsTableField.TYPE,
            flex: 1,
            width: 200,
            headerName: "Type",
            valueGetter: (params: GridValueGetterParams) =>
              params.row.spec.type,
            renderCell: renderTypeCell,
          };
        case DestinationsTableField.ICON_AND_NAME:
          return {
            field: DestinationsTableField.ICON_AND_NAME,
            flex: 1,
            headerName: "Name",
            valueGetter: (params: GridValueGetterParams) => {
              return {
                type: params.row.spec.type,
                name: params.row.metadata.name,
              };
            },
            sortComparator: (v1, v2: { name: string; type: string }) => {
              return v1.name.localeCompare(v2.name);
            },
            renderCell: renderNameAndIconCell,
          };
        case DestinationsTableField.LOGS:
          return createMetricRateColumn(
            "destination",
            field,
            "logs",
            configurationMetrics,
            period,
          );
        case DestinationsTableField.METRICS:
          return createMetricRateColumn(
            "destination",
            field,
            "metrics",
            configurationMetrics,
            period,
          );
        case DestinationsTableField.TRACES:
          return createMetricRateColumn(
            "destination",
            field,
            "traces",
            configurationMetrics,
            period,
          );
        case DestinationsTableField.CONFIGURATIONS:
          return {
            field: DestinationsTableField.CONFIGURATIONS,
            headerName: "Configurations",
            flex: 1,
            width: 100,
            sortable: false,
            renderCell: (cellParams) => (
              <ConfigurationCountCell
                resourceName={cellParams.row.metadata?.name as string}
                resourceType="destination"
              />
            ),
          };
        case DestinationsTableField.DATA_OUT:
          return {
            field: DestinationsTableField.DATA_OUT,
            headerName: "Data Out (24h)",
            flex: 1,
            sortable: true,
            renderCell: renderDataOutCell,
            valueGetter: (params) => {
              const logsOut =
                logsTelemetry?.destinationsSummary?.find(
                  (d) => d.destinationName === params.row.metadata.name,
                )?.totalEgress ?? 0;
              const metricsOut =
                metricsTelemetry?.destinationsSummary?.find(
                  (d) => d.destinationName === params.row.metadata.name,
                )?.totalEgress ?? 0;
              const tracesOut =
                tracesTelemetry?.destinationsSummary?.find(
                  (d) => d.destinationName === params.row.metadata.name,
                )?.totalEgress ?? 0;
              return logsOut + metricsOut + tracesOut;
            },
          };
        default:
          return { field: DestinationsTableField.TYPE };
      }
    });

    return (
      <DataGridPro
        {...dataGridProps}
        checkboxSelection={isFunction(setSelectionModel) && allowSelection}
        onRowSelectionModelChange={handleRowSelectionModelChange}
        slots={{
          noRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              No Destinations
            </Stack>
          ),
        }}
        pagination
        autoHeight={dataGridProps.autoHeight} // used on Library
        style={!dataGridProps.autoHeight ? { minHeight, maxHeight } : undefined} // used on Overview
        disableRowSelectionOnClick
        getRowId={(row) => row.metadata.name}
        columns={columns}
        rowSelectionModel={selectionModel}
        rows={destinations ?? []}
        initialState={dataGridProps.initialState}
        sortingOrder={["asc", "desc"]}
      />
    );
  },
);

function renderTypeCell(cellParams: GridCellParams<any, string>): JSX.Element {
  return <DestinationTypeCell type={cellParams.value ?? ""} />;
}

function renderStringCell(
  cellParams: GridCellParams<any, string>,
): JSX.Element {
  return <>{cellParams.value}</>;
}

DestinationsDataGrid.defaultProps = {
  minHeight: "calc(100vh - 250px)",
  columnFields: [
    DestinationsTableField.NAME,
    DestinationsTableField.TYPE,
    DestinationsTableField.LOGS,
    DestinationsTableField.METRICS,
    DestinationsTableField.TRACES,
  ],
};
