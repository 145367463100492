import { ApolloProvider } from "@apollo/client";
import { Auth0Provider } from "@auth0/auth0-react";
import { StyledEngineProvider, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { SnackbarProvider } from "notistack";
import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import APOLLO_CLIENT from "./apollo-client";
import { AuthType } from "./graphql/generated";
import {
  AgentInstallPage,
  AgentPage,
  AgentsPage,
  ConfigurationsPage,
  NewConfigurationPage,
} from "./pages";
import { AcceptInvitationPage } from "./pages/accept-invitation";
import { AccountPage, SetupAccountPage } from "./pages/account";
import { AppOnboarding } from "./pages/app-onboarding";
import { AuditLogsPage } from "./pages/audit-logs/AuditLogsPage";
import { ConfigurationPage } from "./pages/configurations/configuration";
import { NewRawConfigurationPage } from "./pages/configurations/new-raw";
import { EulaRequiredPage } from "./pages/eula-required/EulaRequiredPage";
import { LicenseRequiredPage } from "./pages/license-required/LicenseRequiredPage";
import { LoginPage } from "./pages/login";
import { OverviewPage } from "./pages/overview/OverviewPage";
import { SummaryPage } from "./pages/overview/SummaryPage";
import { ResourceLibraryPage } from "./pages/resource-library/ResourceLibraryPage";
import { SignUpPage } from "./pages/signup";
import { theme } from "./theme";
import { getAuthType } from "./utils/get-auth-type";

import "reactflow/dist/style.css";

export const App: React.FC = () => {
  const googleClientId = getGoogleClientId();

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <ApolloProvider client={APOLLO_CLIENT}>
          <GoogleOAuthProvider clientId={googleClientId}>
            {appWithAuthProvider(
              <SnackbarProvider>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <BrowserRouter>
                    <QueryParamProvider adapter={ReactRouter6Adapter}>
                      <Routes>
                        {/* Unauthenticated Routes */}
                        <Route path="/login" element={<LoginPage />} />
                        <Route
                          path="/accept-invitation"
                          element={<AcceptInvitationPage />}
                        />
                        <Route path="/sign-up" element={<SignUpPage />} />

                        {/** App Routes */}
                        <Route path="/overview">
                          <Route index element={<OverviewPage />} />
                          <Route path="summary" element={<SummaryPage />} />
                        </Route>
                        <Route path="/" element={<Navigate to="/overview" />} />
                        <Route path="agents">
                          <Route index element={<AgentsPage />} />
                          <Route
                            path="install"
                            element={<AgentInstallPage />}
                          />
                          <Route path=":id">
                            <Route index element={<AgentPage />} />
                          </Route>
                        </Route>
                        <Route path="configurations">
                          <Route index element={<ConfigurationsPage />} />
                          <Route
                            path="new-raw"
                            element={<NewRawConfigurationPage />}
                          />
                          <Route
                            path="new"
                            element={<NewConfigurationPage />}
                          />
                          <Route path=":name" element={<ConfigurationPage />} />
                        </Route>
                        <Route path="resource-library">
                          <Route index element={<ResourceLibraryPage />} />
                        </Route>
                        <Route path="account">
                          <Route index element={<AccountPage />} />
                          <Route
                            path="/account/new"
                            element={<SetupAccountPage />}
                          />
                        </Route>
                        <Route path="audit-logs">
                          <Route index element={<AuditLogsPage />} />
                        </Route>
                        <Route
                          path="license-required"
                          element={<LicenseRequiredPage />}
                        />
                        <Route
                          path="eula-required"
                          element={<EulaRequiredPage />}
                        />
                        <Route
                          path="/getting-started"
                          element={<AppOnboarding />}
                        />
                      </Routes>
                    </QueryParamProvider>
                  </BrowserRouter>
                </LocalizationProvider>
              </SnackbarProvider>,
            )}
          </GoogleOAuthProvider>
        </ApolloProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

/**
 * Only wrap the app with Auth0Provider if the auth type is Auth0.
 * Otherwise we get an error on insecure origin
 */
const appWithAuthProvider: React.FC<React.ReactElement> = (children) => {
  const authType = getAuthType();
  if (authType === AuthType.Auth0) {
    return (
      <Auth0Provider domain={getAuth0Domain()} clientId={getAuth0ClientId()}>
        {children}
      </Auth0Provider>
    );
  }
  return <>{children}</>;
};

function getGoogleClientId(): string {
  try {
    return __GOOGLE_CLIENT_ID__;
  } catch (err) {
    return "";
  }
}

function getAuth0Domain(): string {
  try {
    return __AUTH0_DOMAIN__;
  } catch (err) {
    return "";
  }
}

function getAuth0ClientId(): string {
  try {
    return __AUTH0_CLIENT_ID__;
  } catch (err) {
    return "";
  }
}
