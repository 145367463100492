import { useAuth0 } from "@auth0/auth0-react";
import { Button, Card, CardContent, Stack, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BindplaneVersion } from "../../components/BindplaneVersion";
import { BindPlaneLogo } from "../../components/Logos";
import lights from "../../lights.png";
import { handleLoginResponse } from "./handleLoginResponse";

import colors from "../../styles/colors";
import styles from "./login.module.scss";

/**
 * Body to send to BindPlane to login
 */
interface Auth0Body extends Auth0Token {
  email?: string;
  invitation: string | null;
}

/**
 * Response from Auth0 to requesting access token
 */
interface Auth0Token {
  idToken: string;
  accessToken: string;
}

export const Auth0LoginPage: React.FC = () => {
  const [error, setError] = useState<null | string>(null);
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { user, isAuthenticated, loginWithPopup, getAccessTokenSilently } =
    useAuth0();
  const [token, setToken] = useState<Auth0Token | null>(null);

  const getToken = useCallback(async () => {
    try {
      const accessToken = await getAccessTokenSilently({
        detailedResponse: true,
      });
      setToken({
        idToken: accessToken.id_token,
        accessToken: accessToken.access_token,
      });

      try {
        if (error) {
          setError(null);
        }

        const body: Auth0Body = {
          idToken: accessToken.id_token,
          accessToken: accessToken.access_token,
          email: user?.email,
          invitation: searchParams.get("invitation"),
        };

        const resp = await fetch("/login", {
          method: "POST",
          body: JSON.stringify(body),
        });

        handleLoginResponse({
          status: resp.status,
          onSuccess: async () => {
            if (error) {
              setError(null);
            }

            localStorage.setItem("user", "auth0User");
            navigate("/agents");
          },
          on401: async () => {
            setError("Failed to login.");
          },
          on403: async () => {
            if (error) {
              setError(null);
            }
            localStorage.setItem("user", "auth0User");
            navigate("/account/new");
          },
          on409: async () => {
            if (error) {
              setError(null);
            }
            enqueueSnackbar("Sorry, the invitation is invalid.", {
              variant: "error",
            });
          },
          onFailure: async () => {
            setError("Failed to login.");
          },
        });
      } catch (err) {
        console.error(err);
        enqueueSnackbar("Oops! Something went wrong.", { variant: "error" });
      }
    } catch (e) {
      console.error({ e });
      setError("Failed to get token");
    }
  }, [
    enqueueSnackbar,
    error,
    getAccessTokenSilently,
    navigate,
    searchParams,
    setToken,
    user,
  ]);

  /**
   * Get token once authenticated, and if token is not already set
   */
  useEffect(() => {
    if (isAuthenticated && token == null) {
      getToken();
    }
  }, [getToken, isAuthenticated, token]);

  return (
    <>
      <div className={styles["login-page"]} data-testid="login-page">
        <img
          src={lights}
          alt="lights.png"
          width={"100%"}
          style={{
            position: "fixed",
            top: "-10rem",
            left: "-0.1rem",
          }}
        />
        <Stack alignItems={"center"} justifyContent={"center"}>
          <BindPlaneLogo width={225} height={60} className={styles.logo} />
          <Card classes={{ root: styles.card }}>
            <CardContent>
              <Typography variant="h5" fontWeight={600} marginBottom={3}>
                Sign In
              </Typography>
              <Stack alignItems={"center"}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => loginWithPopup({})}
                  style={{ width: 300, height: 100 }}
                >
                  <Typography variant="h6" color={colors.white}>
                    Sign in with Email or Google
                  </Typography>
                </Button>
                {error && <Typography color="error">{error}</Typography>}
              </Stack>
            </CardContent>
          </Card>
        </Stack>
      </div>
      <footer>
        <BindplaneVersion />
      </footer>
    </>
  );
};
